import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import * as Scroll from 'react-scroll'
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from 'react-scroll'

import Logo from '../images/logo.png'
import Burger from '../images/burger.png'

const NavWrapper = styled.nav`
  display: flex;
  width: 100%;
  transition: background 0.3s ease-in-out;
  background: ${props => (props.scrolled ? '#000' : '')};
  box-shadow: ${props => (props.scrolled ? '0 4px 15px rgba(0,0,0,0.3)' : '')};

  img {
    width: 270px;
    padding: 1rem;
  }
  ul {
    flex-grow: 1;
  }

  @media (max-width: 1100px) {
    background: ${props => (props.scrolled ? '#000' : '#000')};
    img {
      width: 220px;
      padding: 1rem;
      padding-left: 0rem;
    }
  }
`
const MenuRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: white;
  font-size: 1.1rem;
  text-transform: uppercase;

  ul {
    display: flex;
    margin: 0;
    list-style-type: none;
    margin-right: 4rem;
  }

  li {
    margin: 0;
    margin-left: 2rem;
    font-weight: 600;

    @media (max-width: 968px) {
      margin-left: 0;
    }
  }

  a {
    color: ghostwhite;
    text-decoration: none;
    opacity: 0.95;
    transition: all 0.25s ease-in-out;

    &:hover {
      opacity: 1;
      color: crimson;
      cursor: pointer;
    }
  }

  .menuright__mobile {
    display: none;
    margin-right: 1rem;
    cursor: pointer;

    img {
      width: auto;
      height: 75px;
      opacity: 0.9;
      transition: opacity 0.3s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }
  }

  .menuright__mobile-links {
    position: absolute;
    width: 100%;
    top: 84px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(0, 0, 0, 0.98);
    padding: 3rem 1rem;

    li {
      margin-bottom: 3rem;
    }
  }

  @media (max-width: 1100px) {
    .menuright__desktop {
      display: none;
    }
    .menuright__mobile {
      display: block;
    }
  }
`

const Nav = () => {
  const [scrolled, setScrolled] = useState(false)
  const [mobileOpen, setMobileOpen] = useState(false)
  useEffect(() => {
    window.addEventListener('scroll', scrollY)

    return () => {
      window.removeEventListener('scroll', scrollY)
    }
  }, [])

  useEffect(() => {
    const { scrollHeight, scrollTop, clientHeight } = document.documentElement

    if (scrollTop < 800) {
      setScrolled(false)
    }
  })

  const scrollY = e => {
    const y = window.scrollY
    if (y > 500) {
      setScrolled(true)
    }
  }

  return (
    <NavWrapper
      scrolled={scrolled}
      style={{ position: 'fixed', zIndex: '3000' }}
    >
      <ul>
        <li>
          <Link to="/">
            <img src={Logo} alt="Logo" />
          </Link>
        </li>
      </ul>
      <MenuRight>
        <ul className="menuright__desktop">
          <Link to="About" smooth={true} offset={-30} duration={500}>
            <li>Qui est Walter?</li>
          </Link>
          <Link to="Services" smooth={true} offset={-30} duration={500}>
            <li>Services</li>
          </Link>

          <Link to="Reals" smooth={true} offset={-30} duration={500}>
            <li>Nos Cr&eacute;ations</li>
          </Link>
          <Link to="Contact" smooth={true} offset={-30} duration={500}>
            <li>Contact</li>
          </Link>
        </ul>
        <div
          className="menuright__mobile"
          onClick={() => setMobileOpen(!mobileOpen)}
        >
          <img src={Burger} alt="Menu" />
          {mobileOpen && (
            <ul className="menuright__mobile-links">
              <Link to="About" smooth={true} offset={-30} duration={500}>
                <li onClick={() => setMobileOpen(false)}>Qui est Walter?</li>
              </Link>
              <Link to="Services" smooth={true} offset={-30} duration={500}>
                <li onClick={() => setMobileOpen(false)}>Services</li>
              </Link>

              <Link to="Reals" smooth={true} offset={-30} duration={500}>
                <li onClick={() => setMobileOpen(false)}>
                  Nos Cr&eacute;ations
                </li>
              </Link>
              <Link to="Contact" smooth={true} offset={-30} duration={500}>
                <li
                  onClick={() => setMobileOpen(false)}
                  style={{ marginBottom: 0 }}
                >
                  Contact
                </li>
              </Link>
            </ul>
          )}
        </div>
      </MenuRight>
    </NavWrapper>
  )
}

export default Nav
