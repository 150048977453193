import React from 'react'
import styled from 'styled-components'

const FooterWrapper = styled.div`
  background: #000;
  color: ghostwhite;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  padding: 2.4rem;
  font-family: 'Nunito Sans', sans-serif;

  p {
    font-size: 1.1rem;
    text-align: center;
  }
`

const Footer = () => {
  const year = new Date()
  const years = year.getFullYear()

  return (
    <FooterWrapper>
      <p>
        &copy; Copyright {years} Walter Production. Tous droits
        r&eacute;serv&eacute;s.
      </p>
    </FooterWrapper>
  )
}

export default Footer
