import React from 'react'
import styled from 'styled-components'

import Facebook from '../images/fbook.png'
import Instagram from '../images/insta.png'
import Linkedin from '../images/linkedin.png'

const SocialWrapper = styled.div`
  background: ghostwhite;
  height: 60px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 75px rgba(0, 0, 0, 0.08);
`

const SocialContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  backface-visibility: hidden;

  h4 {
    color: #333;
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0;
    margin-right: 1rem;
    color: crimson;
    /* letter-spacing: 5px; */
  }

  .social__icon a {
    transition: all 0.3s ease-in-out;

    &:hover {
      cursor: pointer;
      transform: translateY(-2px);
    }
  }
`

const Social = () => {
  return (
    <SocialWrapper>
      <SocialContainer>
        <a
          href="https://www.facebook.com/productionwalter/"
          target="_blank"
          rel="norel nofollower"
        >
          <img
            className="social__icon"
            src={Facebook}
            alt="Facebook"
            width={50}
            height={50}
            style={{ marginRight: '0.5rem' }}
          />
        </a>
        <a
          href="https://www.instagram.com/walterproduction"
          target="_blank"
          rel="norel nofollower"
        >
          <img
            className="social__icon"
            src={Instagram}
            alt="Instagram"
            width={50}
            height={50}
            style={{ marginRight: '0.5rem' }}
          />
        </a>
        <a
          href="https://www.linkedin.com/in/josiane-vallieres-5428ba160/"
          target="_blank"
          rel="norel nofollower"
        >
          <img
            className="social__icon"
            src={Linkedin}
            alt="Linkedin"
            width={50}
            height={50}
          />
        </a>
      </SocialContainer>
    </SocialWrapper>
  )
}

export default Social
